<template>
  <l-tela titulo="Cadastro de usuários">
    <l-form :salvar="salvar">
      <i-basico
        ref="inputNomeUsuario"
        id="nome"
        label="Nome"
        v-model="form.nome"
        :validations="{ required: true, min: 3 }"
      />

      <i-contato
        id="contato"
        label="Contato"
        v-model="form.contato"
        :validations="{ validaLenCel: true }"
      />

      <i-cargo
        id="cargo"
        label="Cargo"
        v-model="form.cargo"
        :validations="{ required: true }"
      />

      <i-check
        v-if="!novoRegistro"
        id="usuarioAtivo"
        label="Usuário ativo?"
        v-model="form.ativo"
      />

      <i-basico
        id="login"
        label="Login"
        v-model="form.login"
        :disabled="!novoRegistro"
        :validations="{ required: true, min: 4 }"
      />

      <i-basico
        id="senha"
        label="Nova senha"
        v-model="form.senha"
        :validations="{ min: 6 }"
      />

      <template v-slot:botoes>
        <v-btn @click="sair">Sair</v-btn>
        <v-btn color="primary" type="submit">Salvar</v-btn>
      </template>
    </l-form>
  </l-tela>
</template>

<script>
import store from '@/store'
import usuarioService from '@/services/usuario.service'
import LoadingMixin from '@/components/Mixins/Loading'
import NotificacaoMixin from '@/components/Mixins/Notificacao'

export default {
  mixins: [LoadingMixin, NotificacaoMixin],
  data() {
    return {
      novoRegistro: true,
      form: {
        _id: null,
        login: '',
        nome: '',
        contato: '',
        ativo: true,
        cargo: null,
        senha: '',
      },
    }
  },
  mounted() {
    const { usuarioId } = this.$route.params
    if (usuarioId) {
      this.novoRegistro = false
      this.carregar(usuarioId)
    } else {
      // this.$refs.inputNomeUsuario.definirFoco()
    }
  },
  methods: {
    async carregar(cargoId) {
      this.mostrarLoading()
      try {
        const { _id, login, nome, contato, ativo, cargo } =
          await usuarioService.buscaPorId(cargoId)

        this.form = { _id, login, nome, contato, ativo, cargo }
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
    async salvar() {
      this.mostrarLoading()
      try {
        const dadosUsuario = store.getters['dadosUsuario']
        if (dadosUsuario && dadosUsuario.empresa) {
          let salvarDto = {
            senha: this.form.senha,
            nome: this.form.nome,
            contato: this.form.contato,
            cargoId: this.form.cargo._id,
            empresaId: dadosUsuario.empresa,
          }

          if (this.form._id) {
            salvarDto._id = this.form._id
            salvarDto.ativo = this.form.ativo

            await usuarioService.atualizar(salvarDto)
          } else {
            salvarDto.login = this.form.login
            await usuarioService.cadastrar(salvarDto)
            this.sair()
          }
          this.alertaDadosSalvos()
        }
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
    sair() {
      this.$router.push({ name: 'rota-usuarios' })
    },
  },
}
</script>
