import Vue from 'vue'
import MensagensHelper, {
  MSG_ALERTA,
  MSG_SUCESSO,
  MSG_INFO,
} from '@/utils/mensagem.helper'

export default {
  methods: {
    // { tipo: Tipo do alerta, mensagem: Array de erros }
    mostraNotificacao(objMensagens) {
      const { tipo, mensagens } = objMensagens
      if (mensagens) {
        switch (tipo) {
          case MSG_SUCESSO: {
            mensagens.forEach((mensagem) => {
              Vue.$toast.success(mensagem, {
                position: 'top-right',
              })
            })
            break
          }

          case MSG_ALERTA: {
            mensagens.forEach((mensagem) => {
              Vue.$toast.warning(mensagem, {
                position: 'top-right',
              })
            })
            break
          }

          case MSG_INFO: {
            mensagens.forEach((mensagem) => {
              Vue.$toast.info(mensagem, {
                position: 'top-right',
              })
            })
            break
          }

          default: {
            mensagens.forEach((mensagem) => {
              Vue.$toast.error(mensagem, {
                position: 'top-right',
              })
            })
          }
        }
      }
    },
    alertaDadosSalvos() {
      this.mostraNotificacao(
        MensagensHelper.gerar(MSG_SUCESSO, 'Dados salvos com sucesso')
      )
    },
    alertaRegistroExcluido() {
      this.mostraNotificacao(
        MensagensHelper.gerar(MSG_INFO, 'Registro excluído com sucesso')
      )
    },
  },
}
